<template>
  <v-dialog
    v-model="isBookReservationDialogOpen"
    max-width="500px"
    persistent
  >
    <v-form
      ref="bookReservationForm"
      @submit.prevent="handleSubmitForm"
    >
      <v-card>
        <v-card-title>
          {{ $t('BookReservation') }}
        </v-card-title>

        <v-card-text>
          <div class="">
            <DatePicker
              v-model="date"
              :label="$t('Day')"
              :rules="[validators.required]"
            />
          </div>
        </v-card-text>

        <v-card-text>
          <div class="">
            <TimePicker
              v-model="startTime"
              :label="$t('StartTime')"
              :rules="[validators.required]"
            />
          </div>
        </v-card-text>

        <v-card-text>
          <div class="">
            <TimePicker
              v-model="endTime"
              :label="$t('EndTime')"
              :rules="[validators.required]"
            />
          </div>
        </v-card-text>

        <v-card-text>
          <v-textarea
            v-model="description"
            dense
            outlined
            :label="$t('Description')"
            :placeholder="$t('Description')"
            :rules="[validators.required]"
            hide-details="auto"
            validate-on-blur
          ></v-textarea>
        </v-card-text>

        <v-card-text>
          <v-btn
            color="primary"
            block
            type="submit"
            :loading="loading"
          >
            {{ $t('Save') }}
          </v-btn>

          <v-btn
            class="mt-4"
            outlined
            block
            @click="$emit('onClose')"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs'
import DatePicker from '@/components/DatePicker.vue'
import TimePicker from '@/components/TimePicker.vue'
import { ref } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import SkillShareService from '@/services/SkillShareService'
import store from '@/store'

const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(customParseFormat)

export default {
  components: { DatePicker, TimePicker },
  props: {
    isBookReservationDialogOpen: {
      type: Boolean,
      required: true,
    },
    selectedDate: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const bookReservationForm = ref(null)
    const date = ref(props.selectedDate || '')
    const startTime = ref('')
    const endTime = ref('')
    const fromTime = ref('')
    const toTime = ref('')
    const description = ref('')

    const loading = ref(false)

    const handleSubmitForm = async () => {
      const isFormValid = bookReservationForm.value.validate()
      if (isFormValid) {
        fromTime.value = dayjs(`${date.value} ${startTime.value}`, 'YYYY-MM-DD HH:mm').unix()
        toTime.value = dayjs(`${date.value} ${endTime.value}`, 'YYYY-MM-DD HH:mm').unix()

        loading.value = true
        try {
          const newReservationData = {
            start_time: fromTime.value,
            end_time: toTime.value,
            note: description.value,
          }
          await SkillShareService.createReservation(newReservationData)
          store.commit('snackbar/show', {
            message: 'Book reservation successfully',
            type: 'success',
          })
          emit('onSubmit')
        } catch (e) {
        } finally {
          loading.value = false
        }
      }
    }

    return {
      bookReservationForm,
      handleSubmitForm,
      loading,
      date,
      startTime,
      endTime,
      fromTime,
      toTime,
      description,

      validators: {
        required,
      },
    }
  },
}
</script>

<template>
  <div>
    <v-card>
      <div>
        <v-card-title>
          {{ userData.full_name }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="6"
              class="d-flex align-center"
            >
              <v-avatar
                size="44"
                color="primary"

                rounded
                class="elevation-1"
              >
                <v-icon
                  dark
                  color="white"
                  size="30"
                >
                  {{ icons.mdiSetAll }}
                </v-icon>
              </v-avatar>

              <div class="ms-2">
                <p class="text-xs mb-0">
                  {{ $t('Joined') }}
                </p>
                <h3 class="text-xl font-weight-bold">
                  {{ userData.skill_share_joined }}
                </h3>
              </div>
            </v-col>
            <v-col
              cols="6"
              class="d-flex align-center"
            >
              <v-avatar
                size="44"
                color="success"
                outlined
                rounded
                class="elevation-1"
              >
                <v-icon
                  dark
                  color="white"
                  size="30"
                >
                  {{ icons.mdiAccountVoice }}
                </v-icon>
              </v-avatar>

              <div class="ms-2">
                <p class="text-xs mb-0">
                  {{ $t('Speakers') }}
                </p>
                <h3 class="text-xl font-weight-bold">
                  {{ userData.skill_share_speakers }}
                </h3>
              </div>
            </v-col>
          </v-row>
          <v-row class="pb-4">
            <v-col
              class="pb-0"
              :cols="12"
              :md="isVipUser ? 4 : 6"
            >
              <v-btn
                color="primary"
                block
                :to="{name:'skill-share-create'}"
              >
                {{ $t('RegisterSkillShare') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="isVipUser"
              class="pb-0"
              cols="12"
              md="4"
            >
              <v-btn
                color="warning"
                outlined
                block
                @click="showBookReservationDialog"
              >
                {{ $t('BookReservation') }}
              </v-btn>
            </v-col>
            <v-col
              class="pb-0"
              :cols="12"
              :md="isVipUser ? 4 : 6"
            >
              <v-btn
                color="primary"
                outlined
                block
                :to="{name:'skill-share-checkin'}"
              >
                {{ $t('Checkin') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <SkillShareReservationDialog
        :key="dialogKey"
        :selected-date="selectedDate"
        :is-book-reservation-dialog-open.sync="isBookReservationDialogOpen"
        @onSubmit="handleBookReservationSuccessfully"
        @onClose="handleCloseReservationDialog"
      />
    </v-card>
    <v-tabs
      v-model="activeTab"
      class="mt-4"
      icons-and-text
      grow
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.routeName"
        :to="{ name: tab.routeName }"
        exact
      >
        {{ $t(tab.title) }}
        <v-icon>{{ tab.icon }}</v-icon>
      </v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>
<script>
import { mdiCalendarText, mdiViewList, mdiSetAll, mdiAccountVoice } from '@mdi/js'
import { ref, provide, computed } from '@vue/composition-api'
import store from '@/store'
import SkillShareReservationDialog from './components/SkillShareReservationDialog.vue'

export default {
  components: {
    SkillShareReservationDialog,
  },
  setup() {
    const dialogKey = ref(0)
    const activeTab = ref('skill-share-list')
    const userData = ref({ ...store.getters['auth/currentUser'] })
    const isVipUser = computed(() => userData.value.permission?.manage_reservations)
    const isBookReservationDialogOpen = ref(false)
    const selectedDate = ref(null)
    const tabs = [
      { title: 'List', routeName: 'skill-share-list', icon: mdiViewList },
      { title: 'Calendar', routeName: 'skill-share-calendar', icon: mdiCalendarText },
    ]

    const items = ['All', 'Joined / Wait line', 'Owner', 'Wait Apporal', 'Not Joined']

    const handleBookReservationSuccessfully = () => {
      isBookReservationDialogOpen.value = false
    }

    const handleCloseReservationDialog = () => {
      isBookReservationDialogOpen.value = false
      selectedDate.value = null
      dialogKey.value += 1
    }

    const showBookReservationDialog = () => {
      isBookReservationDialogOpen.value = true
      dialogKey.value += 1
    }

    provide('userData', userData)
    provide('showBookReservationDialog', showBookReservationDialog)
    provide('bookReservationDialogKey', dialogKey)
    provide('selectedDate', selectedDate)

    return {
      selectedDate,
      dialogKey,
      items,
      userData,
      isVipUser,
      activeTab,
      tabs,
      isBookReservationDialogOpen,
      showBookReservationDialog,
      handleBookReservationSuccessfully,
      handleCloseReservationDialog,
      icons: { mdiSetAll, mdiAccountVoice },
    }
  },
}
</script>
<style lang="scss" scoped>
img {
  max-width: 100%;
  border: 1px solid #ccc;
  display: block !important;
  margin: 0 auto;
}
</style>
